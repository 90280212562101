const cooperations = [
    {
        text: "eeaser beschleunigt mit datenbasierten Dienstleistungen die industrielle Energieeffizienz. Unsere Software identifiziert Energieeffizienzpotentiale und beschleunigt die Realisierung von Maßnahmen durch die Bereitstellung von Fachwissen. Darüber hinaus bieten wir Energieaudits (nach DIN 16247 / mit Begehung) an welche Daten fundiert erheben sowie Maßnahmen detailliert bewerten. Diese Audits werden mit bis zu 80% gefördert. Energieeffizienz in Unternehmen hängt aber ebenso von der Organisationsstruktur ab – daher freuen wir uns über die Zusammenarbeit mit der Proceed GmbH um unseren Kunden ganzheitlich bei der Energiewende zu begleiten." ,
        header:"Accelerating industrial energy efficiency" ,
        name:"eeaser",
        image: "partner_14",
        link:"https://www.eeaser.com/"
    },
    {
        text: "Die Produkte der Zukunft verlangen eine andere Art der Organisation! axxelia begleitet Industrieunternehmen auf ihrem individuellen Weg zum Unternehmen der 4.0 Generation. Wir betrachten Betriebe aus einer neuen Perspektive. Dafür verbinden wir kostbare Erfahrung aus der industriellen Praxis mit revolutionären digitalen Lösungen und bahnbrechenden Methodiken. Mit diesem Wissen - quasi als Sparringspartner für Produktionsunternehmen -  implementieren wir seit Jahren erfolgreich maßgeschneiderte ERP- und MES-Systeme bis hin zu IoT-Lösungen. Dabei sind wir mehr als nur Softwareentwickler: Unsere Lösungen entstehen immer in Symbiose mit den geeigneten Methoden zur Organisation, wie z.B. Agile, Lean, QRM, etc.So entstehen Organisationen, die ideal auf die komplexen Herausforderungen der Zukunft vorbereitet sind – Vorzeigeunternehmen ihrer Branche." ,
        header:"Smart Factory Software - ahead of the curve" ,
        name:"axxelia",
        image: "partner_01",
        link:"https://www.axxelia.com/"
    },
    {
        text: "Die AEP Solutions GmbH aus Darmstadt ist Anbieter von Planungs- und Steuerungssystemen, allen voran unser AEP System Solution4. Neben der Entwicklung und dem Vertrieb von Software für A(dvanced) E(nterprise) P(lanning) und Business Intelligence bieten wir Beratung zu Prozessoptimierung, Risikoanalyse und Organisationsentwicklung sowie zu allgemeinen betriebswirtschaftlichen und produktionstechnischen Problemstellungen an. Im Rahmen unserer Themenschwerpunkte führen wir als zugelassene Partner Beratungsprojekte für das RKW durch. Zusätzlich bieten wir Schulungen, Vorträge, Weiterbildungsmaßnahmen und Vorlesungen an." ,
        header: "Prozessoptimierung - Risikoanalyse - Organisationsentwicklung" ,
        name: "AEP Solutions",
        image: "partner_06",
        link: "https://www.aep-solutions.com/" 
    },
    {
        text:"Wir tragen zu Lösung des Fachkräftemangels bei. An 13 Standorten in Baden-Württemberg und Bayern befähigen wir Mitmenschen, damit sie (wieder) arbeits- und qualifizierungsfähig werden. Darauf aufbauend führen wir seit über 35 Jahren förderfähige Aus- und Weiterbildungen im gewerblich-technischen Bereich durch. In Unternehmensprojekten gelingt es uns durch gezielte Qualifizierung der Mitarbeitenden, relevanten Unternehmenskennzahlen zu verbessern." ,
        header:"Wir glauben, dass jeder/e ein/e Facharbeiter/in in der Industrie 4.0 Welt sein kann!" ,
        name:"Quantum Gesellschaft für berufliche Bildung mbH",
        image: "partner_07",
        link:"http://quantum-bildung.jetzt/"
    },
    {
        text:"In sich exponentiell verändernden Zeiten sind sich anpassende Geschäftsmodelle, neue Arbeitsprozesse basierend auf humanistischen Zusammenarbeitsmodellen sowie zeitgemäße Organisationsstrukturen unverzichtbar. Insbesondere im deutschen Mittelstand liegt ein unglaubliches Potential. Dr. Arndt Pechstein begleitet Unternehmen bei der Dualen Transformation: dem gleichzeitigen Optimieren des Status Quo UND der harmonisierten Transformation in Richtung Future Business. Dabei ist es wichtig, Menschen im Prozess mitzunehmen sowie die Organisation als Ganzes zukunftsfähig zu machen. Das von Arndt und seinem Team entwickelte digitale Tool, der Transformation Quotient (TQ), unterstützt Organisationen bei ihrer Transformation in vier Dimensionen (Organisationskultur, Organisationsstruktur & Effektivität, Geschäftsmodell & Wirkung sowie Zukunftsexzellenz). Abgeleitet von der Funktionsweise lebender Systeme unterstützt der TQ-Prozess bei der Etablierung einer lernenden, erfolgreichen und zukunftsfähigen Organisation. Während der Transformation Quotient ausgehend vom Status Quo Veränderungspotentiale sichtbar und Fortschritte messbar macht, dient der begleitende Prozess zur Implementierung von Agilität, Innovationskraft und Anpassungsfähigkeit. „Future-Proofing Organizations for the Benefit of Humanity“",
        header:"Tradition ist kein Geschäftsmodell – Future-Proofing Organizations" ,
        name:"Dr. Arndt Pechstein",
        image:"partner_08",
        link:"https://www.arndtpechstein.eu/"
    },
    {
        text:"Dynamische Zeiten erfordern dynamische Unternehmen, denn alles Starre ist nicht zukunftstauglich. Die Veränderungsdynamik braucht die Bereitschaft der Menschen, diese Veränderungen positiv zu gestalten. Konsequent gelebte Leistungskultur ist für Wolfgang Saaman der atmosphärische Rohstoff in Organisationen, um Menschen die zu erbringende Leistung zu erleichtern und ihre Identifizierung mit dem Unternehmen zu erhöhen. Denn jede nicht genutzte Leistungsfähigkeit und -bereitschaft von Mitarbeitern schadet am Ende dem Ergebnis. Wirtschaftlicher und psychologischer Sachverstand vereinen in der SAAMAN SOLUTION Leistung aus Kultur zu einem anspruchsvollen Konzept.",
        header: "Zusammenspiel von Leistung und Kultur" ,
        name:"SAAMAN SOLUTION",
        image: "partner_12",
        link:"https://saaman.de/"
    },
    {
        text:"Die Logistik der Zukunft verlangen zügige Veränderungen der Prozessabläufe im Bereich Verpacken, Bilden von Ladeeinheiten und Sichern von Ladungen im Land-See- und Luftverkehr. EUROSAFE begleitet mit Ihrem Expertenwissen Europäische Industrieunternehmen auf ihrem individuellen Weg zur Umsetzung der rechtlichen und physikalischen Anforderungen für Ihre Logistik. „Mehr Wissen in die Industrie“ Für die Umsetzung sind nicht nur praktische Kennnisse und Expertenwissen erforderlich. Vielmehr ist eine Integration neuer Verfahren und Wege in einer international tätigen Organisation erforderlich. Die Umsetzung in international ausgerichteten Unternehmen wird in Kooperation mit unserem Partner PROCEED GmbH vorangebracht.",
        header: "Ladungssicherung, Seminare, Zertifizierung, Beratung und Gutachten aus einer Hand." ,
        name:"EUROSAFE",
        image: "partner_13",
        link:"https://www.euro-safe.eu/"
    }
 
]

export default cooperations;