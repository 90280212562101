import appStyles from "@appStyles";
import styled from "@emotion/styled"

const ButtonMediumExtern = ({text, link, color}) => {
    const Button =  styled.button({
        fontFamily: "Montserrat",
        fontWeight: 500,
        textTransform: "none",
        width: "auto",
        borderRadius: "6px",
        padding: "0.6em 1.2em",
        letterSpacing: "0.06em",
        fontSize: "0.9em",
        backgroundColor: color,
        color: appStyles.colors.white,
        cursor: "pointer",
        border: "none",
    })
    return (
        <a href={link} target="__blank">
            <Button className="buttonShadow">
                {text}
            </Button>
        </a>
    )
}

export default ButtonMediumExtern;