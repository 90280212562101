import React, { useState } from 'react'
import { motion } from 'framer-motion';
import { ButtonMediumExtern } from '@components/buttons';
import { displayHeader, partnerDisplayContainer, textContainer } from '../styles';
import PartnerImage from '../../../components/images-partner';
import appStyles from '@appStyles';

const PartnerComponent = ({name, text, header, link, image }) => {
	const [isExpanded, setIsExpanded] = useState(false);
	const toggleOpen = () => setIsExpanded(!isExpanded);
    return (
        <div css={partnerDisplayContainer} className="buttonShadow">
            <div>
                <motion.div
                    css={displayHeader}
                    onClick={() => toggleOpen()}
                    layout
                >
                    <PartnerImage image={image} />
                    <h4 css={{marginTop: '1.2rem'}}>
                        {header}
                    </h4>
                    <p css={{fontSize: appStyles.fontSize.small, textTransform: 'uppercase'}}>
                        mehr Erfahren...
                    </p>
                </motion.div>
                <motion.div
                    style={{height:0}}
                    animate={{height: isExpanded === false ? 0 : 'auto'}}
                    css={textContainer}
                >
                    <p>{text} </p>
                </motion.div>
            </div>
            <ButtonMediumExtern link={link} color={appStyles.colors.orange} text={"Zur Homepage von " + name}></ButtonMediumExtern>
        </div>
  )
}

export default PartnerComponent