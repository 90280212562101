import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill";

const PartnerImage = (props) => {
  const data = useStaticQuery(graphql`
    query {
      partner_01: file(relativePath: { eq: "partner/axxelia_logo_we_accelerate_huge.png" }) {
        childImageSharp {
          fixed(height: 60, grayscale: false, quality: 100) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      },
      partner_02: file(relativePath: { eq: "partner/contempo.png" }) {
        childImageSharp {
          fixed(height: 60, grayscale: false, quality: 75) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      },
      partner_03: file(relativePath: { eq: "partner/dana.png" }) {
        childImageSharp {
          fixed(height: 60, grayscale: false, quality: 75) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      },
      partner_04: file(relativePath: { eq: "partner/conworx.jpg" }) {
        childImageSharp {
          fixed(height: 60, grayscale: false, quality: 75) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      },
      partner_05: file(relativePath: { eq: "partner/rosset.png" }) {
        childImageSharp {
          fixed(height: 60, grayscale: false, quality: 75) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      },
      partner_06: file(relativePath: { eq: "partner/AEP-Solutions-GmbH-R.jpg" }) {
        childImageSharp {
          fixed(height: 60, grayscale: false, quality: 100) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      },
      partner_07: file(relativePath: { eq: "partner/quantum.png" }) {
        childImageSharp {
          fixed(height: 60, grayscale: false, quality: 100) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      },
      partner_08: file(relativePath: { eq: "partner/arndt-pechstein.jpg" }) {
        childImageSharp {
          fixed(height: 60, grayscale: false, quality: 100) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      },
      partner_09: file(relativePath: { eq: "partner/Thales-Akademie.png" }) {
        childImageSharp {
          fixed(height: 60, grayscale: false, quality: 100) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      },
      partner_10: file(relativePath: { eq: "partner/Manufaktur-Fuehrungskultur.jpg" }) {
        childImageSharp {
          fixed(height: 60, grayscale: false, quality: 100) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      },
      partner_11: file(relativePath: { eq: "partner/Weltethos-Institut.png" }) {
        childImageSharp {
          fixed(height: 60, grayscale: false, quality: 100) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      },
      partner_12: file(relativePath: { eq: "partner/saaman_solution.png" }) {
        childImageSharp {
          fixed(height: 60, grayscale: false, quality: 100) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      },
      partner_13: file(relativePath: { eq: "partner/eurosafe.jpg" }) {
        childImageSharp {
          fixed(height: 60, grayscale: false, quality: 100) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      },
      partner_14: file(relativePath: { eq: "partner/easer.png" }) {
        childImageSharp {
          fixed(height: 60, grayscale: false, quality: 100) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      },
      partner_15: file(relativePath: { eq: "partner/logo_lk.png" }) {
        childImageSharp {
          fixed(height: 60, grayscale: false, quality: 100) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
    } 
  `)
  
  if (props.image === "partner_01")  return  <Img alt="Axxelia" fixed={data.partner_01.childImageSharp.fixed} objectFit="contain" objectPosition="50% 50%" css={{margin: "0.6rem"}}  />
  if (props.image === "partner_02")  return  <Img alt="Contempo" fixed={data.partner_02.childImageSharp.fixed} objectFit="contain" objectPosition="50% 50%"  />
  if (props.image === "partner_03")  return  <Img alt="Dana" fixed={data.partner_03.childImageSharp.fixed} objectFit="contain" objectPosition="50% 50%"  />
  if (props.image === "partner_04")  return  <Img alt="Conworx" fixed={data.partner_04.childImageSharp.fixed} objectFit="contain" objectPosition="50% 50%"  />
  if (props.image === "partner_05")  return  <Img alt="Rosset" fixed={data.partner_05.childImageSharp.fixed} objectFit="contain" objectPosition="50% 50%"  />
  if (props.image === "partner_06")  return  <Img alt="AEP Solutions GmbH" fixed={data.partner_06.childImageSharp.fixed} objectFit="cover" objectPosition="50% 50%"  />
  if (props.image === "partner_07")  return  <Img alt="Quantum GmbH" fixed={data.partner_07.childImageSharp.fixed} objectFit="cover" objectPosition="50% 50%"  />
  if (props.image === "partner_08")  return  <Img alt="Arndt Pechstein" fixed={data.partner_08.childImageSharp.fixed} objectFit="cover" objectPosition="50% 50%"  />
  if (props.image === "partner_09")  return  <Img alt="Thales Akademie" fixed={data.partner_09.childImageSharp.fixed} objectFit="cover" objectPosition="50% 50%"  />
  if (props.image === "partner_10")  return  <Img alt="Manufaktur für Führungskultur im Mittelstand e.V." fixed={data.partner_10.childImageSharp.fixed} objectFit="cover" objectPosition="50% 50%"  />
  if (props.image === "partner_11")  return  <Img alt="Weltethos Institut" fixed={data.partner_11.childImageSharp.fixed} objectFit="cover" objectPosition="50% 50%"  />
  if (props.image === "partner_12")  return  <Img alt="SAAMAN SOLUTION" fixed={data.partner_12.childImageSharp.fixed} objectFit="contain" objectPosition="50% 50%"  />
  if (props.image === "partner_13")  return  <Img alt="EUROSAFE" fixed={data.partner_13.childImageSharp.fixed} objectFit="contain" objectPosition="50% 50%"  />
  if (props.image === "partner_14")  return  <Img alt="easer" fixed={data.partner_14.childImageSharp.fixed} objectFit="contain" objectPosition="50% 50%"  />
  if (props.image === "partner_15")  return  <Img alt="Leistungskultur e.V." fixed={data.partner_15.childImageSharp.fixed} objectFit="contain" objectPosition="50% 50%"  />
  else return null
}

export default PartnerImage;
