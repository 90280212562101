import React from "react";
import Image from "../../components/image"
import SEO from "../../components/seo"
import { MediumOverline, SmallOverline } from "../../components/overline";
import PartnerImage from "./components/images-partner";
import ContactFormSite from "../../components/forms/contact-form-site";
import { EventButton } from "../../components/events/event-sorter";
import {css} from '@emotion/react';
import PartnerDisplay from "./content/PartnerDisplay";
import { Container, FlexBox, Wrapper } from "@components/layout";
import appStyles from "@appStyles";

const Kooperationen = ({props}) => {
    return (
      <>
        <Wrapper background={appStyles.colors.background2} isTopHeader>
          <Container> 
            <SEO 
            title="Kooperationen" 
            pictureUrl={props.pageContext.contextData.metaImages[0].beratung.url} 
            description="Bestens vorbereitet in unserem Partnernetzwerk" 
            />
            <EventButton />
            <FlexBox direction="row" justify="space-between" align="center" changeToColumn>
              <FlexBox direction="column" styles={{width: ['100%', '100%', '50%'],overflow: 'visible', padding: '1em'}}> 
                <MediumOverline>Kooperationen</MediumOverline>
                <h1>
                  Bestens vorbereitet in unserem Partnernetzwerk
                </h1>
                <h5>
                  Proceed steht nicht alleine da. Wir bündeln die Ressourcen unseres Netzwerks. Damit jeder Bereich Ihres Unternehmens mit der Kompetenz unter die Lupe genommen wird, die es verdient.  
                </h5>
              </FlexBox>
                <FlexBox styles={{display: ['none', 'none', 'inline-block'], width: '50%', height: 'auto', overflow: 'visible', position: 'relative'}}>
                  <div css={css`height: 100%; width: 100%; overflow: visible;  opacity: 1; padding: 1.2rem; max-width: 360px; ` }>
                    <Image image="puzzle" />
                  </div>
                </FlexBox>
            </FlexBox>
          </Container>
        </Wrapper>
        <Wrapper background={appStyles.colors.background1}>
          <Container>
            <FlexBox direction="column" hasFullWidth  > 
                <SmallOverline>Partner</SmallOverline>
                <h2>Die Partner in unserem Netzwerk</h2>
                <PartnerDisplay />
            </FlexBox>
          </Container>
        </Wrapper>
        <Wrapper background={appStyles.colors.background2}>
          <Container>
            <FlexBox direction="column" align="center" justify="center" hasFullWidth  > 
                <SmallOverline>Mitgliedschaften</SmallOverline>
                <h2>Weitere Kooperationen und Mitgliedschaften</h2>
                <FlexBox direction="row" align="center" justify="space-evenly" styles={{marginTop: "2.4rem", gap: "1.2rem"}}>
                    <div className="buttonShadow" css={{padding: appStyles.padding.small, background: appStyles.colors.white, cursor: "pointer", height: "auto", width: "auto", position: "relative", fontSize: 0}}>
                        <a href="https://www.thales-akademie.de/" target="_blank">
                        <PartnerImage image="partner_09" />
                        </a>
                    </div>
                    <div className="buttonShadow" css={{padding: appStyles.padding.small, background: appStyles.colors.white, cursor: "pointer", height: "auto", width: "auto", position: "relative", fontSize: 0}}>
                        <a href="https://leistungskultur-ev.de" target="_blank">
                        <PartnerImage image="partner_15" />
                        </a>
                    </div>
                    <div className="buttonShadow" css={{padding: appStyles.padding.small, background: appStyles.colors.white, cursor: "pointer", height: "auto", width: "auto", position: "relative", fontSize: 0}}>
                        <a href="https://weltethos-institut.org/" target="_blank">
                        <PartnerImage image="partner_11" />
                        </a>
                    </div>
                </FlexBox>
            </FlexBox>
          </Container>
        </Wrapper>
          
        <Wrapper background={appStyles.colors.background1}>
          <Container>
            <ContactFormSite />
          </Container>
        </Wrapper>
        </>
    )
  }
  
export default Kooperationen
