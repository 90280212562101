import appStyles from '@appStyles'
import { FlexBox } from '@components/layout'
import React from 'react'
import cooperations from './constants/cooperations'
import PartnerComponent from './components/PartnerComponent'

const PartnerDisplay = () => {
  return (
    <FlexBox 
        direction='row'
        justify='space-between'
        align='flex-start'
        gap={appStyles.sizes.medium}
        styles={{marginBottom: appStyles.sizes.large}}
        hasFullWidth
        wrap='wrap'
    >
        {cooperations.map((value, index) => {
            return (
                <PartnerComponent 
                    name={value.name} 
                    position={index} 
                    text={value.text}                    
                    header={value.header}                    
                    link={value.link}                    
                    image={value.image} 
                />
                )
            })        
        }

    </FlexBox>
  )
}

export default PartnerDisplay