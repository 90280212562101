import appStyles from "@appStyles";

export const partnerDisplayContainer = appStyles.mq({
    width: ['100%', '48%', '48%', '31%'],
    height: 'auto',
    backgroundColor: appStyles.colors.background3,
    padding: appStyles.padding.medium,
    borderRadius: '24px 0 24px 0',
    marginBottom: appStyles.sizes.small,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'

})

export const textContainer = {
    overflow: 'hidden'
}

export const displayHeader = {
    cursor: 'pointer'
}